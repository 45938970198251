import constants from '@/utils/constants'

const ROLES = constants.users

export default [
  {
    width: '40px',
    value: 'edit',
    align: 'center',
    colModifiers:[
      { type: 'edit' }
    ],
    editPath: 'returns/edit/',
    access: Object.values(ROLES)
  },
  {
    text: '!',
    value: 'attention',
    align: 'center',
    width: '40px',
    customFilter: {
      type: 'list',
      options: [
        { value: 1, text: 'Есть изменения' },
        { value: 2, text: 'Нет изменений' }
      ]
    },
    access: [ROLES.admin, ROLES.manager, ROLES.user]
  },
  {
    text: 'Бронь',
    value: 'booking_date',
    customFilter: {
      type: 'datepicker'
    },
    colModifiers:[
      {
        type: 'min-width',
        value: '80px'
      }
    ],
    access: Object.values(ROLES)
  },
  {
    text: 'Факт',
    value: 'displayed_booking_date_fact',
    customFilter: {
      type: 'datepicker'
    },
    colModifiers:[
      {
        type: 'color',
        value: 'success'
      },
      {
        type: 'min-width',
        value: '80px'
      }
    ],
    access: Object.values(ROLES)
  },
  {
    text: 'Код',
    value: 'application_code',
    colModifiers:[
      {
        type: 'min-width',
        value: '100px'
      }
    ],
    customFilter: {
      type: 'search',
      label: 'Поиск по коду:',
      inputType: 'number'
    },
    access: Object.values(ROLES)
  },
  {
    text: 'Поставщик',
    value: 'supplier',
    customFilter: {
      type: 'multiple-autocomplete',
      label: 'Поиск по поставщику:'
    },
    colModifiers:[
      {
        type: 'min-width',
        value: '170px'
      }
    ],
    access: Object.values(ROLES)
  },
  {
    text: 'Кол-во',
    value: 'pallets_num',
    customFilter: {
      type: 'search',
      label: 'Введите количество:',
      inputType: 'number'
    },
    colModifiers:[
      {
        type: 'min-width',
        value: '80px'
      }
    ],
    access: Object.values(ROLES)
  },
  {
    text: 'Кол-во факт',
    value: 'pallets_num_fact',
    customFilter: {
      type: 'search',
      label: 'Введите количество:',
      inputType: 'number'
    },
    colModifiers:[
      {
        type: 'color',
        value: 'success'
      },
      {
        type: 'min-width',
        value: '80px'
      }
    ],
    access: [ROLES.admin, ROLES.manager, ROLES.user]
  },
  {
    text: 'Сеть',
    value: 'network',
    customFilter: {
      type: 'list',
      options: []
    },
    colModifiers:[
      {
        type: 'min-width',
        value: '100px'
      }
    ],
    access: Object.values(ROLES)
  },
  {
    text: 'Регион',
    value: 'region',
    customFilter: {
      type: 'multiple-autocomplete',
      label: 'Поиск по региону'
    },
    colModifiers:[
      {
        type: 'min-width',
        value: '100px'
      }
    ],
    access: Object.values(ROLES)
  },
  {
    text: 'РЦ',
    value: 'werk',
    customFilter: {
      type: 'multiple-autocomplete',
      label: 'Поиск по РЦ'
    },
    colModifiers:[
      {
        type: 'min-width',
        value: '300px'
      }
    ],
    access: Object.values(ROLES)
  },
  {
    text: 'Точка возврата',
    value: 'return_point',
    customFilter: {
      type: 'multiple-autocomplete',
      label: 'Поиск по точке возврата'
    },
    colModifiers:[
      {
        type: 'min-width',
        value: '100px'
      }
    ],
    access: Object.values(ROLES)
  },
  {
    text: 'Склад',
    value: 'store',
    customFilter: {
      type: 'multiple-autocomplete',
      label: 'Поиск по складу:'
    },
    colModifiers:[
      {
        type: 'min-width',
        value: '150px'
      }
    ],
    access: [ROLES.admin, ROLES.manager, ROLES.user]
  },
  {
    text: 'Статус X5',
    value: 'status',
    customFilter: {
      type: 'multiple-autocomplete',
      label: 'Писк по статусу X5'
    },
    colModifiers:[
      {
        type: 'min-width',
        value: '100px'
      }
    ],
    access: Object.values(ROLES)
  },
  {
    text: 'Причина отмены',
    value: 'cancel_reason',
    customFilter: {
      type: 'search',
      label: 'Введите причину'
    },
    colModifiers:[
      {
        type: 'min-width',
        value: '100px'
      }
    ],
    access: Object.values(ROLES)
  },
  {
    text: 'Статус',
    value: 'return_status',
    customFilter: {
      type: 'multiple-autocomplete',
      label: 'Поиск по статусу'
    },
    colModifiers:[
      {
        type: 'min-width',
        value: '100px'
      }
    ],
    access: Object.values(ROLES)
  },
  {
    text: 'Детализация статуса',
    value: 'status_detail',
    customFilter: {
      type: 'autocomplete',
      label: 'Поиск по детализации статуса'
    },
    colModifiers:[
      {
        type: 'min-width',
        value: '100px'
      }
    ],
    access: [ROLES.admin, ROLES.manager, ROLES.user]
  },
  {
    text: 'Номер Заказа в 1С',
    value: 'number_1c',
    customFilter: {
      type: 'search',
      label: 'Поиск по номеру 1С:'
    },
    colModifiers:[
      {
        type: 'min-width',
        value: '150px'
      }
    ],
    access: Object.values(ROLES)
  },
  {
    text: 'Комментарий',
    value: 'comment',
    customFilter: {
      type: 'search',
      label: 'Введите комментарий:'
    },
    colModifiers:[
      {
        type: 'max-width',
        value: '200px'
      }
    ],
    access: [ROLES.admin, ROLES.manager, ROLES.user]
  },
  {
    text: 'Номер ТС',
    value: 'ts_number',
    customFilter: {
      type: 'search',
      label: 'Введите номер:'
    },
    colModifiers:[
      {
        type: 'min-width',
        value: '70px'
      }
    ],
    access: Object.values(ROLES)
  }
]
