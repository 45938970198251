import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,_vm._b({},'v-dialog',_vm.$attrs,false),[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_vm._t("body",function(){return [_c(VCardText,[_vm._v(" "+_vm._s(_vm.text)+" ")])]}),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){return _vm.$emit('input', false)}}},[_vm._v(" Ок ")])],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }