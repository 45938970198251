<template>
  <tr
    :class="classes"
    v-on-clickaway="away"
    @click="handleRowClick"
  >
    <template v-if="isEditAccess">
      <td
        v-if="isReturnsManagementModeEnabled"
      >
        <rcc-checkbox
          ref="checkboxRef"
          :input-value="row.id"
          v-model="selected"
          name="is_cancel"
          class="checkbox"
          @click.native="handleCancelCheckboxClick"
        />
      </td>
      <td
        v-else
        class="edit"
      >
        <v-btn
          icon
          small
          :disabled="isEditingEnabled"
          @click="handleEditClick"
        >
          <v-icon small>
            mdi-lead-pencil
          </v-icon>
        </v-btn>
      </td>
    </template>
    <td v-if="checkAccess('attention')">
      {{ row.attention }}
    </td>
    <td v-if="checkAccess('booking_date')">
      {{ row.booking_date }}
    </td>
    <td v-if="checkAccess('displayed_booking_date_fact')" class="date-fact">
      <rcc-table-inline-date-input
        v-if="isEditingEnabled"
        v-model="form.booking_date_fact"
        :class="bookingDateFactClasses"
      />

      <span v-else>
        {{ row.displayed_booking_date_fact }}
      </span>
    </td>
    <td v-if="checkAccess('application_code')">
      {{ row.application_code }}
    </td>
    <td v-if="checkAccess('supplier')">
      {{ row.supplier }}
    </td>
    <td v-if="checkAccess('pallets_num')">
      {{ row.pallets_num }}
    </td>
    <td v-if="checkAccess('pallets_num_fact')" class="pallets-num-fact">
      <rcc-table-inline-number-input
        v-if="isEditingEnabled"
        v-model="form.pallets_num_fact"
        :class="palletsFactClasses"
      />

      <span v-else>
        {{ row.pallets_num_fact }}
      </span>
    </td>
    <td v-if="checkAccess('network')">
      {{ row.network }}
    </td>
    <td v-if="checkAccess('region')">
      {{ row.region }}
    </td>
    <td v-if="checkAccess('werk')">
      {{ row.werk }}
    </td>
    <td v-if="checkAccess('return_point')" class="return-point">
      <rcc-table-inline-select
        v-if="isEditingEnabled"
        v-model="form.return_point_id"
        :items="returnPointsForCurrentNetwork"
        show-by="name"
        list-attach
      />

      <span v-else>
        {{ row.return_point }}
      </span>
    </td>
    <td v-if="checkAccess('store')" class="warehouse">
      <rcc-table-inline-select
        v-if="isEditingEnabled"
        v-model="form.store_id"
        show-by="name"
        :items="selectsOptions.warehouses || []"
        list-attach
      />

      <span v-else>
        {{ row.store }}
      </span>
    </td>
    <td v-if="checkAccess('status')">
      {{ row.status }}
    </td>
    <td v-if="checkAccess('cancel_reason')">
      {{ row.cancel_reason}}
    </td>
    <td v-if="checkAccess('return_status')" class="return-status">
      <rcc-table-inline-select
        v-if="isEditingEnabled"
        v-model="form.return_status_id"
        show-by="name"
        :items="selectsOptions.returnStatuses || []"
        list-attach
        @input="handleReturnStatusInput"
      />

      <span v-else>
        {{ row.return_status }}
      </span>
    </td>
    <td v-if="checkAccess('status_detail')" class="details">
      <rcc-table-inline-select
        v-if="isEditingEnabled"
        v-model="form.status_detail_id"
        show-by="name"
        :items="selectsOptions.details || []"
        list-attach
      />

      <span v-else>
        {{ row.status_detail }}
      </span>
    </td>
    <td v-if="checkAccess('number_1c')" class="number-1c">
      <rcc-table-inline-text-input
        v-if="isEditingEnabled"
        v-model="form.number_1c"
      />

      <span v-else>
        {{ row.number_1c }}
      </span>
    </td>
    <td
      v-if="checkAccess('comment')"
      v-tooltip="row.comment"
      class="comment"
    >
      <rcc-table-inline-text-input
        v-if="isEditingEnabled"
        v-model="form.comment"
      />

      <span v-else>
        {{ row.comment }}
      </span>
    </td>
    <td v-if="checkAccess('ts_number')">
      {{ row.ts_number }}
    </td>

    <rcc-confirm-dialog
      :is-show.sync="isShowSubmitConfirm"
      title="Вы уверены, что  хотите сохранить изменения?"
      confirm-text="Да"
      cancel-text="Отмена"
      :confirmCallback="submit"
    />

    <rcc-confirm-dialog
      :is-show.sync="isShowCancelConfirm"
      title="Вы уверены, что хотите сбросить изменения?"
      confirm-text="Сбросить"
      cancel-text="Отмена"
      :confirmCallback="() => $emit('editing-disabled')"
    />
  </tr>
</template>

<script>
import { getReturnPointsList } from '@/api/return-points'
import { mixin as clickaway } from 'vue-clickaway'
import { validationMixin } from 'vuelidate'
import { requiredIf } from 'vuelidate/lib/validators'
import headers from '../headers'

import RccTableInlineDateInput from 'Components/controls/inline_table/date-input'
import RccTableInlineNumberInput from 'Components/controls/inline_table/number-input'
import RccTableInlineSelect from 'Components/controls/inline_table/select'
import RccTableInlineTextInput from 'Components/controls/inline_table/text-input'
import RccConfirmDialog from 'Components/dialogs/rcc-confirm-dialog'
import RccCheckbox from 'Components/controls/rcc-checkbox.vue'

export default {
  name: 'RccReturnTableRow',

  mixins: [ clickaway, validationMixin ],

  components: {
    RccTableInlineDateInput,
    RccTableInlineNumberInput,
    RccTableInlineSelect,
    RccTableInlineTextInput,
    RccConfirmDialog,
    RccCheckbox
  },

  props: {
    isEditingEnabled: {
      type: Boolean,
      default: false
    },

    selectedReturns: {
      type: Array,
      default: () => []
    },

    row: {
      type: Object,
      required: true,
      default: () => ({})
    },

    selectsOptions: {
      type: Object,
      default: () => ({})
    }
  },

  validations: {
    form: {
      booking_date_fact: {
        required: requiredIf(function() {
          return this.hasFactValidation
        })
      },
      pallets_num_fact: {
        required: requiredIf(function() {
          return this.hasFactValidation
        })
      }
    }
  },

  data() {
    return {
      v: false,
      form: {},
      returnPointsForCurrentNetwork: [],
      isShowSubmitConfirm: false,
      isShowCancelConfirm: false
    }
  },

  computed: {
    classes() {
      if (this.isSupplierRole) {
        return 'table-row'
      }

      return [
        'table-row',
        this.row.isAttention && 'is-attention',
        this.isEditingEnabled && 'is-editing',
        !this.isEditingEnabled && this.$store.isTableEditingEnabled && 'is-blocked',
        this.isCancelInProgress && 'is-canceled',
        this.isRowSelected && 'is-selected',
        !this.isEditAccess && 'is-no-editable'
      ]
    },

    isRowSelected() {
      return this.selected.includes(this.row.id)
    },

    isBookingDateValid() {
      return !this.form?.booking_date_fact
        || (
          this.form.booking_date_fact.length === 10
            && this.$moment(this.form.booking_date_fact)._isValid
        )
    },

    hasFactValidation() {
      return  this.form.return_status_id
        && this.selectsOptions.returnStatuses.length > 0
        && this.selectsOptions.returnStatuses.find(({ id }) => id === this.form.return_status_id)?.is_validate
    },

    bookingDateFactClasses() {
      return [
        (
          !this.isBookingDateValid
          || (this.$v.form.booking_date_fact.$dirty && this.$v.form.booking_date_fact.$invalid)
        )
          && 'invalid'
      ]
    },

    palletsFactClasses() {
      return [
        this.$v.form.pallets_num_fact.$dirty && this.$v.form.pallets_num_fact.$invalid && 'invalid'
      ]
    },

    selected: {
      get() {
        return this.selectedReturns
      },

      set(value) {
        this.$emit('update:selected-returns', value)
      }
    },

    isReturnsManagementModeEnabled() {
      return this.$store.isReturnsManagementModeEnabled
    },

    isCancelInProgress() {
      return this.row.process_status === this.$constants.cancelStatuses.IN_PROGRESS
    },

    isEditAccess() {
      return this.$route.meta.editAccess.includes(this.$store.user.role)
    },

    isSupplierRole() {
      return this.$store.user.role === this.$constants.users.supplier
    }
  },

  watch: {
    isEditingEnabled(isEditing) {
      if (isEditing) {
        this.initForm()
      } else {
        this.formDestruct()
      }
    }
  },

  mounted() {
    this.$v.$touch()
  },

  destroyed() {
    this.formDestruct()
  },

  methods: {
    handleEditClick(event) {
      if (this.$store.isTableEditingEnabled) {
        return
      }

      event.stopPropagation()
      this.$router.push({ name: 'returns-edit', params: { id: this.row.id } })
    },

    initForm() {
      this.getFormData()

      if (this.form.network_id && this.returnPointsForCurrentNetwork.length === 0) {
        getReturnPointsList.bind(this)({ network_id: this.form.network_id })
          .then(({ items }) => {
            this.returnPointsForCurrentNetwork = items
          })
      }

      window.addEventListener('keydown', this.handleWindowKeydown,  true)
      window.addEventListener('mousedown', this.handleWindowMousedown)
    },

    getFormData() {
      const {
        id,
        werk_id,
        booking_date_fact,
        pallets_num_fact,
        network_id,
        region_id,
        return_point_id,
        store_id,
        return_status_id,
        status_detail_id,
        number_1c,
        comment
      } = this.row

      this.form = {
        id,
        werk_id,
        booking_date_fact,
        pallets_num_fact,
        network_id,
        region_id,
        return_point_id,
        store_id,
        return_status_id,
        status_detail_id,
        number_1c,
        comment
      }
    },

    handleWindowKeydown(event) {
      if (event.key === 'Enter' && !this.isShowCancelConfirm) {
        event.stopPropagation()
        this.$v.$touch()

        const errors = [
          !this.$v.form.booking_date_fact.required && 'Заполните поле "Дата факт"',
          !this.$v.form.pallets_num_fact.required && 'Заполните поле "Количество паллет факт"',
          !this.isBookingDateValid && 'Невалидные данные в поле "Количество паллет факт"'
        ].filter(error => error)

        errors.forEach(error => {
          window.snackbar(error, 'error')
        })

        if (errors.length > 0) {
          return
        }

        this.isShowSubmitConfirm = true
      } else if (event.key === 'Escape' && !this.isShowSubmitConfirm) {
        this.checkChanges()
      }
    },

    away(event) {
      if (this.isEditingEnabled && !event.target.closest('.inline-control') && !this.isShowSubmitConfirm) {
        this.checkChanges()
      }
    },

    formDestruct() {
      window.removeEventListener('keydown', this.handleWindowKeydown,  true)
      window.removeEventListener('mousedown', this.handleWindowMousedown)
    },

    submit() {
      this.$apiMethods.returns.update(this.form.id, this.form)
        .then(() => {
          this.$emit('saved', {
            ...this.form,
            displayed_booking_date_fact:
              this.form.booking_date_fact && this.$moment(this.form.booking_date_fact).format('DD.MM.YYYY'),
            return_point: this.getSelectText(this.returnPointsForCurrentNetwork, this.form.return_point_id),
            store: this.getSelectText(this.selectsOptions.warehouses, this.form.store_id),
            return_status: this.getSelectText(this.selectsOptions.returnStatuses, this.form.return_status_id),
            status_detail: this.getSelectText(this.selectsOptions.details, this.form.status_detail_id)
          })
          window.snackbar('Заявка успешно изменена', 'info')
        })
    },

    getSelectText(collection, id) {
      return collection?.find(item => item.id === id)?.name
    },

    checkChanges() {
      const keys = Object.keys(this.form)

      const hasChanges = keys.some(key => this.row[key] !== this.form[key])

      if (hasChanges) {
        this.isShowCancelConfirm = true
      } else {
        this.$emit('editing-disabled')
      }
    },

    handleReturnStatusInput() {
      this.$v.$touch()
    },

    handleCancelCheckboxClick(event) {
      event.stopPropagation()
    },

    handleRowClick() {
      if (!this.isEditAccess) {
        return
      }

      if (this.isReturnsManagementModeEnabled) {
        this.$refs.checkboxRef?.$el.click()
        return
      }

      this.$emit('click')
    },

    checkAccess(value) {
      return headers.find(header => header.value === value)?.access?.includes(this.$store.user.role)
    }
  }
}
</script>

<style lang="scss" scoped>
tr {
  pointer-events: all;
  background: transparent;
  line-height: 1.3;
  cursor: pointer;

  th, td {
    padding: $data-table-header-and-footer-paddings !important;
    height: 31px !important;
    font-size: 13px !important;
    white-space: nowrap;
    box-sizing: border-box;
  }

  th {
    background: $data-table-secondary-bg !important;
  }

  .comment {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.is-attention {
    background: $data-table-row-attention-bg;
  }

  &.is-selected {
    background: $data-table-hovered-bg;
  }

  .date-fact {
    min-width: 105px;
  }

  .return-point,
  .warehouse,
  .return-status,
  .details,
  .number-1c {
    min-width: 150px;
  }

  &.is-editing {
    cursor: default;

    td {
      font-size: 13px !important;
    }
  }

  &.is-blocked {
    cursor: default;

    .edit {
      pointer-events: none;

      ::v-deep .v-ripple__container {
        display: none !important;
      }
    }

  }

  &.is-canceled {
    background: #ededed;
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5;
  }

  .invalid {
    ::v-deep fieldset {
      border-color: $error !important;
    }
  }

  &.is-no-editable {
    cursor: default;
  }

  .checkbox {
    display: flex;
    align-items: center;
    ::v-deep .rcc-checkbox__checkbox {
      height: 19px;
      width: 19px;
      padding: 0;
    }
  }
}
</style>
