import { getNetworksList } from '@/api/trading-networks'
import { getDetailsList } from '@/api/detailing'
import { getReturnPointsList } from '@/api/return-points'
import { getDCList } from '@/api/distribution-center'
import { getStatusesList } from '@/api/x5-status'
import { getReturnStatusesList } from '@/api/return-statuses'
import { getAllSuppliersList } from '@/api/suppliers'
import { getWarehousesList } from '@/api/warehouses'
import { getRegionsList } from '@/api/regions'

import { arrayToOptions } from '@/utils/get-options'

import headerData from '../headers'

export default {
  data() {
    return {
      selectsOptions: {}
    }
  },

  computed: {
    headerFiltersData() {
      return [
        {
          filter: 'network',
          name: 'network_id',
          callback: getNetworksList,
          type: 'list',
          access: Object.values(this.$constants.users)
        },
        {
          filter: 'status_detail',
          name: 'status_detail_id',
          callback: getDetailsList,
          type: 'autocomplete',
          access: Object.values(this.$constants.users)
        },
        {
          filter: 'return_point',
          name: 'return_point_id',
          callback: getReturnPointsList,
          access: Object.values(this.$constants.users)
        },
        {
          filter: 'werk',
          name: 'werk_id',
          callback: getDCList,
          access: Object.values(this.$constants.users)
        },
        {
          filter: 'status',
          name: 'status_id',
          callback: getStatusesList,
          access: Object.values(this.$constants.users)
        },
        {
          filter: 'return_status',
          name: 'return_status_id',
          callback: getReturnStatusesList,
          access: Object.values(this.$constants.users)
        },
        {
          filter: 'supplier',
          name: 'supplier_id',
          callback: getAllSuppliersList,
          access: Object.values(this.$constants.users)
        },
        {
          filter: 'store',
          name: 'store_id',
          callback: getWarehousesList,
          access: Object.values(this.$constants.users)
        },
        {
          filter: 'region',
          name: 'region_id',
          callback: getRegionsList,
          access: Object.values(this.$constants.users)
        }
      ].filter(({ access }) => access.includes(this.$store.user.role))
    }
  },

  methods: {
    loadSelectsData() {
      this.isDataLoading = true

      Promise.all([
        getWarehousesList.bind(this)(),
        getReturnStatusesList.bind(this)(),
        getDetailsList.bind(this)()
      ])
        .then(([warehouses, returnStatuses, details]) => {
          this.selectsOptions = {
            warehouses: warehouses.items,
            returnStatuses: returnStatuses.items,
            details: details.items
          }

          this.loadData(this.filtersQuery)
        })
        .catch(() => {
          window.snackbar('Прошла ошибка во время инициализации таблицы, попробуйте перезагрузить страницу', 'error')
          this.disableLoadingIndicator()
        })
    },

    initFilters() {
      this.headerFiltersData.forEach(filter => {
        this.setFilter(filter)
      })
    },

    setFilter({ filter, name, callback, type = 'multiple-autocomplete' }) {
      const index = headerData.findIndex(item => item.value === filter)

      if (index < 0) {
        return
      }

      headerData[index].customFilter.name = name

      if (type === 'multiple-autocomplete') {
        headerData[index].customFilter.callback = callback.bind(this)
      } else if (type === 'list' || type === 'autocomplete') {
        callback.bind(this)().then(({ items }) => {
          headerData[index].customFilter.options = items && arrayToOptions(
            { array: items, keyName: 'value', valueName: 'text' }
          ) || []
        })
      }
    }
  }
}
