<template>
  <div class="returns">
    <div class="returns__actions-bar">
      <div class="returns__actions">
        <rcc-button
          v-if="editAccess && isUserManagerOrAdmin"
          @click="$router.push({ name: 'returns-new' })"
        >
          Добавить возврат
        </rcc-button>

        <rcc-header-filter
          ref="createDateFilter"
          text="Фильтр: Дата создания"
          :filter="{
            type: 'datepicker'
          }"
          @select="addFilter('bedat', 'Дата создания', $event)"
        />

        <rcc-button
          v-if="filters.length > 1"
          color="warning"
          @click="$refs.table.clearFilters()"
        >
          Сбросить все фильтры
        </rcc-button>
      </div>

      <div class="actions-right-column">
        <rcc-returns-management-panel
          v-if="editAccess"
          :returns.sync="rows"
          :selected-items.sync="selectedReturns"
          :statusesOptions="selectsOptions.returnStatuses"
          :is-submit.sync="isSubmit"
        />

        <rcc-button
          v-if="editAccess || isSupplier"
          color="success"
          :is-disabled="isExcelUploadDisabled"
          :is-loading="isExcelUploadLoading || isSubmit"
          @click="handleExcelUploadClick"
        >
          Выгрузить в Excel
        </rcc-button>
      </div>
    </div>

    <div class="returns__table-wrapper">
      <rcc-table
        ref="table"
        :page.sync="page"
        :pageCount="pagesCount"
        :itemsPerPage="rowsOnTable"
        :headers="headers"
        :rows="rows"
        :is-fixed-header="true"
        :isLoading="isDataLoading"
        @filters-changed="changeFilters"
      >
        <template #body>
          <rcc-return-table-row
            v-for="row in rows"
            :key="row.id"
            :row="row"
            :is-editing-enabled="editableRowId === row.id"
            :selects-options="selectsOptions"
            :selected-returns.sync="selectedReturns"
            :is-returns-management-mode-enabled="isReturnsManagementModeEnabled"
            @click="handleRowClick(row.id)"
            @saved="handleRowSaved($event)"
            @editing-disabled="disableEditing"
          />
        </template>
      </rcc-table>
    </div>

    <rcc-dialog
      title="Произошли ошибки во время отмены заявок"
      max-width="700px"
      v-model="isShowDialog"
    >
      <template #body>
        <v-card-text
          v-for="({ order_id: orderId, error }, index) in cancelledErrors"
          :key="index"
        >
          {{ (orderId ? `${orderId} - ` : '') + error }}
        </v-card-text>
      </template>
    </rcc-dialog>

    <rcc-confirm-dialog
      :is-show.sync="isShowConfirm"
      :title="confirmDialogData.title"
      :confirm-text="confirmDialogData.confirmText"
      :cancel-text="confirmDialogData.cancelText"
      :confirmCallback="confirmDialogData.confirmCallback"
      @cancel="() => { this.confirmDialogData = {} }"
    />
  </div>
</template>

<script>
import ReturnsApi from '@/api/returns'
import getXls from '@/utils/get-xls'

import TableList from '@/mixins/table-list'
import Page from '@/mixins/page'

import RccReturnsManagementPanel from 'Components/returns_management_panel'
import RccButton from 'Components/controls/rcc-button'
import RccTable from 'Components/table/rcc-table'
import RccHeaderFilter from 'Components/table/rcc-header-filter'
import RccConfirmDialog from 'Components/dialogs/rcc-confirm-dialog'
import RccDialog from 'Components/dialogs/dialog'
import RccReturnTableRow from './components/table-row'

import filtersMixin from './mixins/filters'
import headersDataMixin from './mixins/headers-data'

const MAX_EXCEL_RECORDS = 1048575

export default {
  components: {
    RccReturnsManagementPanel,
    RccButton,
    RccTable,
    RccHeaderFilter,
    RccReturnTableRow,
    RccConfirmDialog,
    RccDialog
  },

  mixins: [Page, TableList, ReturnsApi, filtersMixin, headersDataMixin],

  data() {
    return {
      selectedReturns: [],
      rowsCount: 0,
      isExcelUploadLoading: false,
      noMixinMount: true,
      editableRowId: 0,
      isShowConfirm: false,
      confirmType: '',
      nextRouteName: null,
      confirmDialogData: {},
      isShowDialog: false,
      cancelledErrors: [],
      isSubmit: false
    }
  },

  beforeRouteLeave(to, from, next) {
    if (this.isReturnsManagementModeEnabled) {
      this.confirmDialogData = {
        title: 'Вы уверены, что хотите сбросить изменения?',
        confirmCallback: () => { next() },
        confirmText: 'Сбросить',
        cancelText: 'Отмена'
      }

      this.isShowConfirm = true
    } else {
      next()
    }
  },

  computed: {
    isSupplier() {
      return this.$store.user.role === this.$constants.users.supplier
    },

    isExcelUploadDisabled() {
      return this.rowsCount > MAX_EXCEL_RECORDS || this.rowsCount === 0
    },

    rowsOnTable() {
      return 200
    },

    isUserManagerOrAdmin() {
      return this.$store.user.role === this.$constants.users.admin
        || this.$store.user.role === this.$constants.users.manager
    },

    isReturnsManagementModeEnabled() {
      return this.$store.isReturnsManagementModeEnabled
    }
  },

  watch: {
    filters(value) {
      this.$store.returnsFilters = value
    }
  },

  created() {
    this.initFilters()
  },

  mounted() {
    this.page = this.$store.returnsCurrentPage || this.page
    this.filters = this.$store.returnsFilters || this.filters
    this.$store.refundChanges = 0

    this.$refs.table.setFilters(this.filters)
    this.loadSelectsData()
  },

  destroyed() {
    this.$store.isReturnsManagementModeEnabled = false
  },

  methods: {
    loadData(filters) {
      this.getReturns({ page: this.page, per_page: this.rowsOnTable, ...filters })
        .then(async({ pages, items, changed, total }) => {
          if (!pages) {
            this.rowsCount = 0
            this.resetTableData()
            this.$store.refundChanges = 0
            this.$store.returnsCurrentPage = 0
            this.disableLoadingIndicator()
            return
          }

          this.$store.refundChanges = changed
          this.$store.returnsCurrentPage = this.page
          this.rowsCount = total
          this.pagesCount = pages
          this.rows = items.map(item => ({
            ...item,
            attention: (item.attention === this.$constants.hasReturnsChanges ? '!': ''),
            booking_date: (item.booking_date && this.$moment(item.booking_date).format('DD.MM.YYYY')),
            displayed_booking_date_fact:
              (item.booking_date_fact && this.$moment(item.booking_date_fact).format('DD.MM.YYYY')),
            isAttention: item.attention === this.$constants.hasReturnsChanges
          }))

          this.disableLoadingIndicator()
        })
        .catch(() => { this.disableLoadingIndicator() })
    },

    handleExcelUploadClick() {
      this.isExcelUploadLoading = true

      this.getExcel(this.filtersQuery)
        .then(response => {
          getXls.bind(this)(response)
        })
        .finally(() => { this.isExcelUploadLoading = false })
    },

    changeFilters(filters) {
      const creationDateFilterIndex = filters.findIndex(filter => filter.name === 'bedat')

      if (creationDateFilterIndex < 0) {
        this.$refs.createDateFilter.resetFilter()
      }

      this.handleFiltersChanged(filters)
    },

    handleRowClick(id) {
      if (this.$store.isTableEditingEnabled) {
        return
      }

      this.$store.isTableEditingEnabled = true
      this.editableRowId = id
    },

    handleRowSaved(row) {
      this.disableEditing()

      this.rows = this.rows.map(item => {
        return item.id === row.id ? { ...item, ...row } : item
      })
    },

    disableEditing() {
      this.$store.isTableEditingEnabled = false
      this.editableRowId = null
    }
  }
}
</script>

<style lang="scss">
.returns {
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: $base-page-padding;

  &__top-buttons-panel {
    display: flex;
    justify-content: space-between;
  }

  &__actions-bar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    background: $main-background;
    align-items: center;
  }

  &__actions {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
  }

  &__table-wrapper {
    flex-grow: 1;
  }

  th:first-child {
    min-width: 40px;
  }

  .actions-right-column {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    align-items: center;
  }
}
</style>
