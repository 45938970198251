<template>
  <v-dialog
    v-bind="$attrs"
  >
    <v-card>
      <v-card-title class="text-h5">
        {{ title }}
      </v-card-title>

      <slot name="body">
        <v-card-text>
          {{ text }}
        </v-card-text>
      </slot>

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="green darken-1"
          text
          @click="$emit('input', false)"
        >
          Ок
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'RccDialog',

  inheritAttrs: false,

  props: {
    title: {
      type: String,
      default: ''
    },

    text: {
      type: String,
      default: ''
    }
  }
}
</script>
