import headers from '../headers'

export default {
  computed: {
    isSupplier() {
      return this.$store.user.role === this.$constants.users.supplier
    },

    headerData() {
      return headers
        .filter(item => item.access.includes(this.$store.user.role))
        .map(item => ((this.isSupplier && item.value === 'supplier') ?
          {
            text: 'Поставщик',
            value: 'supplier',
            colModifiers:[
              {
                type: 'min-width',
                value: '170px'
              }
            ],
            access: this.$constants.users.supplier
          } :
          { ...item }))
    }
  }
}
